import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SumUnreadHighlevelMessagesQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessages?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type SumUnreadHighlevelMessagesForClientQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  clientId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesForClientQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessagesByClient?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type HighlevelMessagesByConversationIdQueryVariables = Types.Exact<{
  conversationId: Types.Scalars["String"]["input"];
  locationId: Types.Scalars["String"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  lastMessageId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type HighlevelMessagesByConversationIdQuery = {
  __typename?: "query_root";
  highlevelMessagesByConversationId?: {
    __typename?: "MessagesType";
    lastMessageId: string;
    nextPage: boolean;
    messages: Array<{
      __typename?: "MessageType";
      id: string;
      userId?: string | null;
      body?: string | null;
      locationId: string;
      contactId: string;
      conversationId: string;
      dateAdded: string;
      direction: Types.MessageDirectionType;
      status?: Types.MessageStatusType | null;
      attachments?: Array<string | null> | null;
      meta?: string | null;
      source?: string | null;
      messageType?: Types.MessageTypeType | null;
    } | null>;
  } | null;
};

export type HighLevelSearchConversationsQueryVariables = Types.Exact<{
  locationId: Types.Scalars["String"]["input"];
  contactId: Types.Scalars["String"]["input"];
  limit: Types.Scalars["Int"]["input"];
  startAfter?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  sortBy?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  sortOrder?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type HighLevelSearchConversationsQuery = {
  __typename?: "query_root";
  highlevelSearchConversations?: {
    __typename?: "ConversationsType";
    total?: number | null;
    conversations?: Array<{
      __typename?: "ConversationType";
      id: string;
      contactId?: string | null;
      locationId?: string | null;
      lastMessageBody?: string | null;
      lastMessageType: Types.ConversationLastMessageTypeValues;
      type: Types.ConversationTypeValues;
      unreadCount: number;
      fullName?: string | null;
      contactName?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null> | null;
  } | null;
};

export type GetHighLevelDashboardUrlQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type GetHighLevelDashboardUrlQuery = {
  __typename?: "query_root";
  highlevelDashboardUrl?: string | null;
};

export type UpdateHighlevelUnreadMessagesCountMutationVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  clientId: Types.Scalars["Int"]["input"];
  count: Types.Scalars["Int"]["input"];
}>;

export type UpdateHighlevelUnreadMessagesCountMutation = {
  __typename?: "mutation_root";
  updateHighLevelUnreadMessagesCount?: {
    __typename?: "UpdateHighLevelUnreadMessagesCount";
    count?: number | null;
  } | null;
};

export const SumUnreadHighlevelMessagesDocument = gql`
  query SumUnreadHighlevelMessages($medspaId: Int!) {
    sumUnreadHighlevelMessages(medspaId: $medspaId) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export type SumUnreadHighlevelMessagesQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesQuery
>;
export type SumUnreadHighlevelMessagesLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesLazyQuery
>;
export type SumUnreadHighlevelMessagesSuspenseQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesSuspenseQuery
>;
export type SumUnreadHighlevelMessagesQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesQuery,
  SumUnreadHighlevelMessagesQueryVariables
>;
export const SumUnreadHighlevelMessagesForClientDocument = gql`
  query SumUnreadHighlevelMessagesForClient($medspaId: Int!, $clientId: Int!) {
    sumUnreadHighlevelMessagesByClient(
      medspaId: $medspaId
      clientId: $clientId
    ) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesForClientQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesForClientQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesForClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export type SumUnreadHighlevelMessagesForClientQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientQuery
>;
export type SumUnreadHighlevelMessagesForClientLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientLazyQuery
>;
export type SumUnreadHighlevelMessagesForClientSuspenseQueryHookResult =
  ReturnType<typeof useSumUnreadHighlevelMessagesForClientSuspenseQuery>;
export type SumUnreadHighlevelMessagesForClientQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesForClientQuery,
  SumUnreadHighlevelMessagesForClientQueryVariables
>;
export const HighlevelMessagesByConversationIdDocument = gql`
  query HighlevelMessagesByConversationId(
    $conversationId: String!
    $locationId: String!
    $limit: Int = 100
    $lastMessageId: String
  ) {
    highlevelMessagesByConversationId(
      conversationId: $conversationId
      locationId: $locationId
      limit: $limit
      lastMessageId: $lastMessageId
    ) {
      messages {
        id
        userId
        body
        locationId
        contactId
        conversationId
        dateAdded
        direction
        status
        attachments
        meta
        source
        messageType
      }
      lastMessageId
      nextPage
    }
  }
`;

/**
 * __useHighlevelMessagesByConversationIdQuery__
 *
 * To run a query within a React component, call `useHighlevelMessagesByConversationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlevelMessagesByConversationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlevelMessagesByConversationIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      locationId: // value for 'locationId'
 *      limit: // value for 'limit'
 *      lastMessageId: // value for 'lastMessageId'
 *   },
 * });
 */
export function useHighlevelMessagesByConversationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export type HighlevelMessagesByConversationIdQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdQuery
>;
export type HighlevelMessagesByConversationIdLazyQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdLazyQuery
>;
export type HighlevelMessagesByConversationIdSuspenseQueryHookResult =
  ReturnType<typeof useHighlevelMessagesByConversationIdSuspenseQuery>;
export type HighlevelMessagesByConversationIdQueryResult = Apollo.QueryResult<
  HighlevelMessagesByConversationIdQuery,
  HighlevelMessagesByConversationIdQueryVariables
>;
export const HighLevelSearchConversationsDocument = gql`
  query HighLevelSearchConversations(
    $locationId: String!
    $contactId: String!
    $limit: Int!
    $startAfter: String
    $sortBy: String
    $sortOrder: String
  ) {
    highlevelSearchConversations(
      locationId: $locationId
      contactId: $contactId
      limit: $limit
      startAfter: $startAfter
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      conversations {
        id
        contactId
        locationId
        lastMessageBody
        lastMessageType
        type
        unreadCount
        fullName
        contactName
        email
        phone
      }
      total
    }
  }
`;

/**
 * __useHighLevelSearchConversationsQuery__
 *
 * To run a query within a React component, call `useHighLevelSearchConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighLevelSearchConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighLevelSearchConversationsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      contactId: // value for 'contactId'
 *      limit: // value for 'limit'
 *      startAfter: // value for 'startAfter'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useHighLevelSearchConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export function useHighLevelSearchConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export function useHighLevelSearchConversationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighLevelSearchConversationsQuery,
    HighLevelSearchConversationsQueryVariables
  >(HighLevelSearchConversationsDocument, options);
}
export type HighLevelSearchConversationsQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsQuery
>;
export type HighLevelSearchConversationsLazyQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsLazyQuery
>;
export type HighLevelSearchConversationsSuspenseQueryHookResult = ReturnType<
  typeof useHighLevelSearchConversationsSuspenseQuery
>;
export type HighLevelSearchConversationsQueryResult = Apollo.QueryResult<
  HighLevelSearchConversationsQuery,
  HighLevelSearchConversationsQueryVariables
>;
export const GetHighLevelDashboardUrlDocument = gql`
  query GetHighLevelDashboardUrl($medspaId: Int!) {
    highlevelDashboardUrl(medspaId: $medspaId)
  }
`;

/**
 * __useGetHighLevelDashboardUrlQuery__
 *
 * To run a query within a React component, call `useGetHighLevelDashboardUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighLevelDashboardUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighLevelDashboardUrlQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useGetHighLevelDashboardUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >(GetHighLevelDashboardUrlDocument, options);
}
export function useGetHighLevelDashboardUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >(GetHighLevelDashboardUrlDocument, options);
}
export function useGetHighLevelDashboardUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetHighLevelDashboardUrlQuery,
    GetHighLevelDashboardUrlQueryVariables
  >(GetHighLevelDashboardUrlDocument, options);
}
export type GetHighLevelDashboardUrlQueryHookResult = ReturnType<
  typeof useGetHighLevelDashboardUrlQuery
>;
export type GetHighLevelDashboardUrlLazyQueryHookResult = ReturnType<
  typeof useGetHighLevelDashboardUrlLazyQuery
>;
export type GetHighLevelDashboardUrlSuspenseQueryHookResult = ReturnType<
  typeof useGetHighLevelDashboardUrlSuspenseQuery
>;
export type GetHighLevelDashboardUrlQueryResult = Apollo.QueryResult<
  GetHighLevelDashboardUrlQuery,
  GetHighLevelDashboardUrlQueryVariables
>;
export const UpdateHighlevelUnreadMessagesCountDocument = gql`
  mutation UpdateHighlevelUnreadMessagesCount(
    $medspaId: Int!
    $clientId: Int!
    $count: Int!
  ) {
    updateHighLevelUnreadMessagesCount(
      medspaId: $medspaId
      clientId: $clientId
      count: $count
    ) {
      count
    }
  }
`;
export type UpdateHighlevelUnreadMessagesCountMutationFn =
  Apollo.MutationFunction<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >;

/**
 * __useUpdateHighlevelUnreadMessagesCountMutation__
 *
 * To run a mutation, you first call `useUpdateHighlevelUnreadMessagesCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHighlevelUnreadMessagesCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHighlevelUnreadMessagesCountMutation, { data, loading, error }] = useUpdateHighlevelUnreadMessagesCountMutation({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useUpdateHighlevelUnreadMessagesCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >(UpdateHighlevelUnreadMessagesCountDocument, options);
}
export type UpdateHighlevelUnreadMessagesCountMutationHookResult = ReturnType<
  typeof useUpdateHighlevelUnreadMessagesCountMutation
>;
export type UpdateHighlevelUnreadMessagesCountMutationResult =
  Apollo.MutationResult<UpdateHighlevelUnreadMessagesCountMutation>;
export type UpdateHighlevelUnreadMessagesCountMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHighlevelUnreadMessagesCountMutation,
    UpdateHighlevelUnreadMessagesCountMutationVariables
  >;
