import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  CHIP_BG_COLOR,
  CHIP_BORDER_COLOR,
  GFE_CHIP_TEXT_COLOR,
} from "@/components/common/gfe/gfeIndicationChip";
import { CardiologyIcon } from "@/components/common/icons";
import { EventColorPalette } from "@/config";
import {
  BLUE,
  MINT,
  SALMON,
  VIOLET,
  WHITE,
  YELLOW,
} from "@/config/mui/colorPalette";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { CalendarViews } from "@/hooks/visits/useCalendarTypeView";
import { VisitStatus } from "@/types";
import { GfeStatus, ServiceMenuItemGfeStatus } from "@/types/gfe";
import { getVisitStatusLabel } from "@/utils/visit";

type EventTileChipsProps = {
  status: VisitStatus;
  view?: CalendarViews;
  gfeGeneralStatusOld: GfeStatus;
  gfeGeneralStatus: ServiceMenuItemGfeStatus;
  duration: number;
};

export const EventTileChips = ({
  status,
  view,
  gfeGeneralStatusOld,
  gfeGeneralStatus,
  duration,
}: EventTileChipsProps) => {
  const { newGfeFlowV2 } = useFeatureFlags();
  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );
  return (
    <Stack flexDirection="row" gap={0.5} alignItems="center">
      {view &&
        view === CalendarViews.DAY &&
        !isMobile &&
        (duration >= 35 || duration <= 25) && (
          <VisitStatusChip status={status} duration={duration} />
        )}
      {newGfeFlowV2 ? (
        <GFEStatusChip
          gfeGeneralStatus={gfeGeneralStatus}
          duration={duration}
          view={view}
        />
      ) : (
        <GFEStatusChipOld
          gfeGeneralStatusOld={gfeGeneralStatusOld}
          duration={duration}
          view={view}
        />
      )}
    </Stack>
  );
};

type GFEStatusChipProps = {
  gfeGeneralStatus: string;
  duration: number;
  view?: CalendarViews;
};

const GFEStatusChip = ({
  gfeGeneralStatus,
  duration,
  view,
}: GFEStatusChipProps) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const showText =
    !isMobile &&
    ((duration > 30 && view === CalendarViews.DAY) || duration > 60);

  if (gfeGeneralStatus === ServiceMenuItemGfeStatus.NOT_NEEDED) return null;

  return (
    <Chip
      size="small"
      label={
        <Box display="flex" alignItems="center" gap={0.5}>
          <CardiologyIcon
            color={GFE_CHIP_TEXT_COLOR[gfeGeneralStatus]}
            size={16}
          />
          {showText && <Typography variant="paragraphSmall">GFE</Typography>}
        </Box>
      }
      sx={{
        border: `1px solid ${CHIP_BORDER_COLOR[gfeGeneralStatus]}`,
        color: GFE_CHIP_TEXT_COLOR[gfeGeneralStatus],
        backgroundColor: CHIP_BG_COLOR[gfeGeneralStatus],
        flexDirection: "row",
        height: duration < 30 ? "16px" : "24px",
      }}
    />
  );
};

export const GfeStatusColorsOld: {
  [key in GfeStatus]: EventColorPalette;
} = {
  [GfeStatus.INCOMPLETE]: SALMON,
  [GfeStatus.EXPIRED]: SALMON,
  [GfeStatus.CLIENT_COMPLETED]: SALMON,
  [GfeStatus.WAITING_FOR_GFE]: YELLOW,
  [GfeStatus.GFE_APPROVED]: MINT,
  [GfeStatus.GFE_DECLINED]: SALMON,
  [GfeStatus.NOT_NEEDED]: MINT,
};

type GFEStatusChipPropsOld = {
  gfeGeneralStatusOld: string;
  duration: number;
  view?: CalendarViews;
};

const GFE_INCOMPLETE = [GfeStatus.INCOMPLETE, GfeStatus.WAITING_FOR_GFE];

// TODO: Remove when newGfeFlowV2 is enabled for all
const GFEStatusChipOld = ({
  gfeGeneralStatusOld,
  duration,
  view,
}: GFEStatusChipPropsOld) => {
  const { newGfeFlowV1 } = useFeatureFlags();
  const theme = useTheme();

  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values["ipad-hor"]}px)`
  );

  const showText =
    !isMobile &&
    ((duration > 30 && view === CalendarViews.DAY) || duration > 60);

  const GFEStatusPalette = GfeStatusColorsOld[gfeGeneralStatusOld] ?? SALMON;

  const isGfeCompleted = !GFE_INCOMPLETE.includes(
    gfeGeneralStatusOld as GfeStatus
  );

  // Temporary solution for GFE v1, will be adjusted when working on GFE v2
  if (newGfeFlowV1 && isGfeCompleted) return null;

  return (
    <Chip
      size="small"
      label={
        <Box display="flex" alignItems="center" gap={0.5}>
          <CardiologyIcon color={GFEStatusPalette[100]} size={16} />
          {showText && <Typography variant="paragraphSmall">GFE</Typography>}
        </Box>
      }
      sx={{
        border: `1px solid ${
          newGfeFlowV1 ? GFEStatusPalette[80] : GFEStatusPalette[100]
        }`,
        color: GFEStatusPalette[100],
        backgroundColor: newGfeFlowV1 ? WHITE : GFEStatusPalette[40],
        flexDirection: "row",
        height: duration < 30 ? "16px" : "24px",
      }}
    />
  );
};

const VisitStatusColors: {
  [key in VisitStatus]: EventColorPalette;
} = {
  [VisitStatus.SCHEDULED]: BLUE,
  [VisitStatus.COMPLETED]: MINT,
  [VisitStatus.CANCELLED]: SALMON,
  [VisitStatus.CANCELLED_LATE]: SALMON,
  [VisitStatus.CONFIRMED]: VIOLET,
  [VisitStatus.NO_SHOW]: SALMON,
};

const VisitStatusChip = ({
  status,
  duration,
}: {
  status: VisitStatus;
  duration: number;
}) => {
  const VisitStatusPalette = VisitStatusColors[status];
  return (
    <Chip
      size="small"
      label={getVisitStatusLabel(status)}
      sx={{
        border: `1px solid ${VisitStatusPalette[80]}`,
        color: VisitStatusPalette[100],
        backgroundColor: VisitStatusPalette[40],
        flexDirection: "row",
        height: duration < 30 ? "16px" : "24px",
      }}
    />
  );
};
