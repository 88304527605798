import { BreakpointsOptions, ChipPropsColorOverrides } from "@mui/material";

// MUI
declare module "@mui/material/styles" {
  interface Palette {
    lightGray: Palette["primary"];
    third: Palette["primary"];
    gray: Palette["primary"];
    green: Palette["primary"];
    yellow: Palette["primary"];
    red: Palette["primary"];
    blue: Palette["primary"];
    violet: Palette["primary"];
  }

  interface PaletteOptions {
    lightGray: PaletteOptions["primary"];
    third: PaletteOptions["primary"];
    gray: PaletteOptions["primary"];
    green: PaletteOptions["primary"];
    yellow: PaletteOptions["primary"];
    red: PaletteOptions["primary"];
    blue: PaletteOptions["primary"];
    violet: PaletteOptions["primary"];
    lightViolet?: PaletteOptions["primary"];
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    "ipad-ver": true;
    "ipad-hor": true;
    desktop: true;
  }

  interface TypographyVariants {
    paragraph: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    subtitleDefault: React.CSSProperties;
    subtitleSmall: React.CSSProperties;
    buttonDefault: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    labelDefault: React.CSSProperties;
    labelSmall: React.CSSProperties;
    labelTiny: React.CSSProperties;
    labelMicro: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    paragraph: React.CSSProperties;
    paragraphSmall: React.CSSProperties;
    subtitleDefault: React.CSSProperties;
    subtitleSmall: React.CSSProperties;
    buttonDefault: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    labelDefault: React.CSSProperties;
    labelSmall: React.CSSProperties;
    labelTiny: React.CSSProperties;
    labelMicro: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    paragraph: true;
    paragraphSmall: true;
    subtitleDefault: true;
    subtitleSmall: true;
    buttonDefault: true;
    buttonSmall: true;
    labelDefault: true;
    labelSmall: true;
    labelTiny: true;
    labelMicro: true;
  }
}

declare module "@emotion/react" {
  export interface Theme {
    breakpoints: BreakpointsOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    third: true;
    violet: true;
    lightGray: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    lightGray: true;
    green: true;
    yellow: true;
    red: true;
    blue: true;
    lightViolet: true;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    secondary: true;
  }
}

// USER
// TODO refactor all references to this to use the enum
export const MEDICAL_DIRECTOR = "medical_director";
export const FRONT_DESK = "front_desk";
export const CLIENT = "client";
export const PROVIDER = "provider";
export const ADMIN = "admin";

export const Roles = {
  ADMIN: "admin",
  CLIENT: "client",
  FRONT_DESK: "front_desk",
  MEDICAL_DIRECTOR: "medical_director",
  PROVIDER: "provider",
  GFE_REVIEWER: "gfe_reviewer", // IMPORTANT: used for UI display only, this role doesn't actually exist in the backend!
} as const;

export type Role = (typeof Roles)[keyof typeof Roles];

export const CLERK_JWT_HASURA_TEMPLATE = { template: "hasura" };

export const HASURA_CLAIMS = "https://hasura.io/jwt/claims";
export const HASURA_USER_ID = "x-hasura-user-id";
export const HASURA_USER_EMAIL = "x-hasura-user-email";
export const HASURA_DEFAULT_ROLE = "x-hasura-default-role";
export const HASURA_ROLES = "x-hasura-allowed-roles";
export const HASURA_MEDSPA_ID = "x-hasura-medspa-id";
export const HASURA_IMPERSONATOR_ID = "x-hasura-impersonator-user-id";
export const HASURA_IMPERSONATED_ID = "x-hasura-impersonated-user-id";
export const TWO_FACTOR_ENABLED = "two-factor-enabled";

export type HasuraClaimsType = {
  [HASURA_USER_ID]: string;
  [HASURA_USER_EMAIL]: string;
  [HASURA_DEFAULT_ROLE]: Role;
  [HASURA_ROLES]: Role[];
  [HASURA_MEDSPA_ID]: string;
  [HASURA_IMPERSONATOR_ID]: string;
  [HASURA_IMPERSONATED_ID]: string;
};

export enum StripeAccountType {
  EXPRESS = "express",
  CUSTOM = "custom",
}

export enum ReviewStatus {
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
  REVIEWED = "reviewed",
  NOT_REQUIRED = "not_required",
  OVERDUE = "overdue",
}

export const ReviewStatusColors: {
  [key in ReviewStatus]: keyof ChipPropsColorOverrides;
} = {
  [ReviewStatus.INCOMPLETE]: "blue",
  [ReviewStatus.COMPLETE]: "yellow",
  [ReviewStatus.REVIEWED]: "green",
  [ReviewStatus.NOT_REQUIRED]: "blue",
  [ReviewStatus.OVERDUE]: "red",
};

export const ChartHistoryReviewStatusColors: {
  [key in ReviewStatus]: keyof ChipPropsColorOverrides;
} = {
  [ReviewStatus.INCOMPLETE]: "lightGray",
  [ReviewStatus.COMPLETE]: "green",
  [ReviewStatus.REVIEWED]: "green",
  [ReviewStatus.NOT_REQUIRED]: "blue",
  [ReviewStatus.OVERDUE]: "red",
};

export const ReviewStatusColorsForCharts: {
  [key in ReviewStatus]: keyof ChipPropsColorOverrides;
} = {
  [ReviewStatus.INCOMPLETE]: "lightGray",
  [ReviewStatus.COMPLETE]: "green",
  [ReviewStatus.REVIEWED]: "green",
  [ReviewStatus.NOT_REQUIRED]: "blue",
  [ReviewStatus.OVERDUE]: "red",
};

export const REVIEW_STATUS_LABEL: Record<ReviewStatus, string> = {
  [ReviewStatus.INCOMPLETE]: "Incomplete",
  [ReviewStatus.COMPLETE]: "Not reviewed",
  [ReviewStatus.REVIEWED]: "Reviewed",
  [ReviewStatus.NOT_REQUIRED]: "Not required",
  [ReviewStatus.OVERDUE]: "Overdue",
};

export const CHART_HISTORY_REVIEW_STATUS_LABEL: Record<ReviewStatus, string> = {
  [ReviewStatus.INCOMPLETE]: "Incomplete",
  [ReviewStatus.COMPLETE]: "Complete",
  [ReviewStatus.REVIEWED]: "Reviewed",
  [ReviewStatus.NOT_REQUIRED]: "Not required",
  [ReviewStatus.OVERDUE]: "Overdue",
};

export enum VisitStatus {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  CANCELLED_LATE = "cancelled_late",
  CONFIRMED = "confirmed",
  NO_SHOW = "no_show",
}

export const ACTIVE_VISIT_STATUSES = [
  VisitStatus.SCHEDULED,
  VisitStatus.CONFIRMED,
  VisitStatus.COMPLETED,
];

export const VisitStatusColors: {
  [key in VisitStatus]: keyof ChipPropsColorOverrides;
} = {
  [VisitStatus.SCHEDULED]: "blue",
  [VisitStatus.COMPLETED]: "green",
  [VisitStatus.CANCELLED]: "red",
  [VisitStatus.CANCELLED_LATE]: "red",
  [VisitStatus.CONFIRMED]: "lightViolet",
  [VisitStatus.NO_SHOW]: "red",
};

export const ClientViewAppointmentStatusColors: {
  [key in VisitStatus]: keyof ChipPropsColorOverrides;
} = {
  [VisitStatus.SCHEDULED]: "blue",
  [VisitStatus.COMPLETED]: "green",
  [VisitStatus.CANCELLED]: "red",
  [VisitStatus.CANCELLED_LATE]: "red",
  [VisitStatus.CONFIRMED]: "green",
  [VisitStatus.NO_SHOW]: "red",
};

export enum NoteType {
  GFE = "gfe",
  CHART = "chart",
  ADVERSE_REACTION = "adverse_reaction",
}

export enum DiscountType {
  ENTIRE_PURCHASE = "entire-purchase",
  SERVICE = "service",
  SERVICE_PRODUCT = "service-product",
  RETAIL_PRODUCT = "retail-product",
}

export enum InvoiceStatus {
  DRAFT = "draft",
  UNPAID = "unpaid",
  PARTIALLY_PAID = "partially_paid",
  PAID = "paid",
  PARTIALLY_REFUNDED = "partially_refunded",
  REFUNDED = "refunded",
}

export const InvoiceStatusColors: {
  [key in InvoiceStatus]: keyof ChipPropsColorOverrides;
} = {
  [InvoiceStatus.DRAFT]: "lightGray",
  [InvoiceStatus.UNPAID]: "red",
  [InvoiceStatus.PARTIALLY_PAID]: "blue",
  [InvoiceStatus.PAID]: "green",
  [InvoiceStatus.REFUNDED]: "yellow",
  [InvoiceStatus.PARTIALLY_REFUNDED]: "yellow",
};

export enum FormValidationError {
  CONSTRAINT_VIOLATION,
}

export enum SubmissionStatus {
  INCOMPLETE = "Incomplete",
  PARTIALLY_COMPLETE = "Partially complete",
  SUBMITTED = "Submitted",
}

export const SubmissionStatusColors: {
  [key in SubmissionStatus]: keyof ChipPropsColorOverrides;
} = {
  [SubmissionStatus.INCOMPLETE]: "lightGray",
  [SubmissionStatus.PARTIALLY_COMPLETE]: "yellow",
  [SubmissionStatus.SUBMITTED]: "green",
};

export const HASURA_INVALID_JWT_ERROR_CODE = "invalid-jwt";

export type Price = "FREE" | "VARIES" | number;

// AVAILABILITY

export const ALLOW_DAY_AND_TIME = "allow_day_and_time";
export const BLOCK_PERIOD = "block_period";
export const ALLOW_PERIOD = "allow_period";

export type Rules =
  | typeof ALLOW_DAY_AND_TIME
  | typeof BLOCK_PERIOD
  | typeof ALLOW_PERIOD;

export type AvailabilityConstraints = Array<{
  [key in Rules]?: {
    day?: string;
    end: string;
    start: string;
  };
}>;

export const MONDAY = "Monday";
export const TUESDAY = "Tuesday";
export const WEDNESDAY = "Wednesday";
export const THURSDAY = "Thursday";
export const FRIDAY = "Friday";
export const SATURDAY = "Saturday";
export const SUNDAY = "Sunday";

export type Day =
  | typeof MONDAY
  | typeof TUESDAY
  | typeof WEDNESDAY
  | typeof THURSDAY
  | typeof FRIDAY
  | typeof SATURDAY
  | typeof SUNDAY;

// UTILS
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export enum MediaType {
  CLIENT = "client",
  OTHER = "other",
  VIDEO = "video",
}

export type KeysMatchingType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

declare global {
  interface Window {
    birdeatsbug?: any;
  }
}

export enum ServiceProtocolStatus {
  DRAFT = "draft",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  UPLOADED = "uploaded",
}

export enum ServiceProtocolUserInterfaceStatus {
  WAITING_FOR_ME = "Waiting for me",
  WAITING_FOR_OTHERS = "Waiting for others",
  COMPLETED = "Completed",
  UNKNOWN = "Unknown",
  UPLOADED = "Uploaded",
  EXPIRED = "Expired",
}

export const ServiceProtocolUserInterfaceStatusColors: {
  [key in ServiceProtocolUserInterfaceStatus]: keyof ChipPropsColorOverrides;
} = {
  [ServiceProtocolUserInterfaceStatus.WAITING_FOR_ME]: "red",
  [ServiceProtocolUserInterfaceStatus.WAITING_FOR_OTHERS]: "yellow",
  [ServiceProtocolUserInterfaceStatus.COMPLETED]: "green",
  [ServiceProtocolUserInterfaceStatus.UNKNOWN]: "lightGray",
  [ServiceProtocolUserInterfaceStatus.UPLOADED]: "blue",
  [ServiceProtocolUserInterfaceStatus.EXPIRED]: "red",
};

export enum PnPDocumentTypes {
  SERVICE_PROTOCOLS = "service-protocols",
  STANDING_ORDERS = "standing-orders",
}

export enum UnitOfMeasurement {
  BOX = "box",
  ML = "ml",
  MG = "mg",
  PACK = "pack",
  PEEL = "peel",
  SYRINGE = "syringe",
  TABLET = "tablet",
  THREAD = "thread",
  TUBE = "tube",
  TIP = "tip",
  UNIT = "unit",
  VIAL = "vial",
}

export enum ContainerType {
  BOX = "box",
  PACK = "pack",
  SYRINGE = "syringe",
  VIAL = "vial",
}

export const isNewUnitOfMeasurement = (
  unit: string
): unit is UnitOfMeasurement =>
  Object.values(UnitOfMeasurement).includes(unit as UnitOfMeasurement);

export enum MembershipStatus {
  NOT_STARTED = "not_started",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export enum MembershipPerkType {
  DISCOUNT = "discount",
  DOLLAR_DEPOSIT = "dollar",
}

export const BILLING_FREQUENCIES = [
  {
    id: "week",
    label: "Weekly",
  },
  {
    id: "month",
    label: "Monthly",
  },
  {
    id: "quarter",
    label: "Quarterly",
  },
  {
    id: "semi-annually",
    label: "Semi-annually",
  },
  {
    id: "annually",
    label: "Annually",
  },
];

export enum ScriptStatus {
  REQUESTED = "requested",
  CANCELLED = "cancelled",
  SENT_TO_PHARMACY = "sent_to_pharmacy",
  FULFILLED = "fulfilled",
  DECLINED = "declined",
}

export const ScriptsStatusColors: {
  [key in ScriptStatus]: keyof ChipPropsColorOverrides;
} = {
  [ScriptStatus.REQUESTED]: "yellow",
  [ScriptStatus.CANCELLED]: "red",
  [ScriptStatus.SENT_TO_PHARMACY]: "yellow",
  [ScriptStatus.FULFILLED]: "blue",
  [ScriptStatus.DECLINED]: "red",
};

export const CLIENT_ACCESS_TOKEN_COOKIE_KEY = "__moxie_cat";

export const ZERO = "0.00";

export function isTab<Tab>(tab: Tab | false): tab is Tab {
  return Boolean(tab);
}

export function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== "string")
    throw new TypeError(`Value ${value} is not a string!`);
}

export enum AdverseReactionStatus {
  REPORTED = "reported",
  RESOLVED = "resolved",
}

export enum MeetingStatus {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum PdfFileSource {
  BOLDSIGN = "boldsign",
  MANUAL_UPLOAD = "manual_upload",
}

export enum ServiceDocumentFormType {
  INTAKE = "intake",
  CONSENT = "consent",
  PRECARE = "precare",
  POSTCARE = "postcare",
}
