import { useMemo } from "react";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import {
  CalendarIcon,
  CalendarUserIcon,
  CapsuleIcon,
  CardiologyIcon,
  CartIcon,
  CoinIcon,
  DocumentIcon,
  MedsIcon,
  MessageIcon,
  PackageIcon,
  PinpaperFilledIcon,
  ReportsIcon,
  SettingsIcon,
  ShareIcon,
  ShieldCheckIcon,
  UserQuestionIcon,
  UserWarningIcon,
  UsersIcon,
  WarningCircleIcon,
} from "@/components/common/icons";
import MoneyIcon from "@/components/common/icons/moneyIcon";
import ComplianceHubIssueCounterNotificationDot from "@/components/common/navigation/complianceHubIssueCounterNotificationDot";
import { OverdueChartsNotificationsDot } from "@/components/common/navigation/overdueChartsNotificationsDot";
import PendingAdverseReactionsNotificationDot from "@/components/common/navigation/pendingAdverseReactionsNotificationDot";
import { MOXIE_HELP_DESK_URL } from "@/config";
import { useMedspaId } from "@/hooks/common/useMedspaId";
import { Role, Roles } from "@/types";
import useFeatureFlags from "../common/useFeatureFlags";
import useMoxieBalancePermissions from "../common/useMoxieBalancePermissions";
import { useIsEligibleForSelfReviewGfe } from "../user/useIsEligibleForSelfReviewGfe";
import { useIsGfeReviewer } from "../user/useIsGfeReviewer";

type NavItem = {
  title: string;
  path?: string;
  getIsActive?: (url: string) => boolean;
  onClick?: () => void;
  icon: React.ElementType;
  isExternal?: boolean;
  notifications?: React.ElementType;
};

export type NavDataType = {
  subheader?: string;
  items: NavItem[];
}[];

const DedicatedSettingsItem = {
  SERVICES_AND_PRODUCTS: "services-and-products",
  CLINICAL_DOCUMENTS: "clinical-documents",
  BILLING: "billing",
};

const filterForVisibleItems = (navData: NavDataType): NavDataType =>
  navData.map((group) => ({
    ...group,
    items: group.items.filter(Boolean),
  }));

export default function useNavigationData(role: Role) {
  const { userMedspa } = useUser();
  const medspa = useMedspaId();
  const {
    newGfeFlowV1,
    balanceEnabled,
    complianceHubV1Enabled,
    complianceMdMeetingsV1Enabled,
    adverseReactionsV1Enabled,
    mdChartsV1Enabled,
    syncGfeV1Enabled,
    contentVaultV1Enabled,
    commandAiEnabled,
  } = useFeatureFlags();
  const isEligibleForSelfGfeReview = useIsEligibleForSelfReviewGfe();
  const isProviderOwner = userMedspa?.isProviderOwner;
  const isGfeReviewer = useIsGfeReviewer();
  const { showBalance } = useMoxieBalancePermissions();

  const navData = useMemo(() => {
    const ReviewerGfeItem: NavItem = {
      title: "GFEs",
      path: `/${medspa}/${newGfeFlowV1 ? "review" : "review-visits"}/gfes`,
      getIsActive: (url) => {
        if (newGfeFlowV1 && url.includes("review/gfe")) return true;
        if (!newGfeFlowV1 && url.includes("review-visits/gfe")) return true;
        if (syncGfeV1Enabled && url.includes("review/sync-gfe")) return true;
        return false;
      },
      icon: CardiologyIcon,
    };

    const SettingsItem: NavItem = {
      title: "Settings",
      path: `/${medspa}/settings`,
      getIsActive: (url) => {
        const isDedicatedItem = Object.values(DedicatedSettingsItem).some(
          (item) => url.includes(item)
        );

        return isDedicatedItem ? false : url.includes("settings");
      },
      icon: SettingsIcon,
    };

    const ClientsItem: NavItem = {
      title: "Clients",
      path: `/${medspa}/clients`,
      icon: UsersIcon,
    };

    const HelpDeskItems: NavItem[] = [
      // New Help Desk button, powered by CommandAi HelpHub
      commandAiEnabled && {
        title: "Help Desk",
        onClick: () => {
          if (window.CommandBar) {
            window.CommandBar.toggleHelpHub();
          } else {
            toast.error(
              "Help Desk is not compatible with this browser. Please try another device or reach out to your PSM for assistance."
            );
          }
        },
        icon: UserQuestionIcon,
      },
      // Redirect to old Help Desk, powered by public Hubspot Knowledge Base
      !commandAiEnabled && {
        title: "Help Desk",
        path: MOXIE_HELP_DESK_URL,
        isExternal: true,
        icon: UserQuestionIcon,
      },
    ];

    const navDataProvider: NavDataType = [
      {
        items: [
          {
            title: "Schedule",
            path: `/${medspa}/visits`,
            icon: CalendarIcon,
          },
          ClientsItem,
          {
            title: "Messages",
            path: `/${medspa}/messages`,
            icon: MessageIcon,
          },
          {
            title: "Charts",
            path: `/${medspa}/charts`,
            icon: PinpaperFilledIcon,
            notifications: OverdueChartsNotificationsDot,
          },
          newGfeFlowV1 && isEligibleForSelfGfeReview && ReviewerGfeItem,
          {
            title: "Inventory",
            path: `/${medspa}/inventory`,
            icon: PackageIcon,
          },
          // Must be provider owner or PC owner to see balance page + feature flag enabled
          balanceEnabled &&
            showBalance && {
              title: "Balance",
              path: `/${medspa}/balance`,
              icon: CoinIcon,
            },
          isProviderOwner && {
            title: "Supplies",
            path: `/${medspa}/supplies`,
            icon: CartIcon,
          },
          contentVaultV1Enabled && {
            title: "Marketing",
            path: `/${medspa}/marketing/content-vault`,
            icon: ShareIcon,
          },
          isProviderOwner && {
            title: "Bookkeeping",
            path: `/${medspa}/bookkeeping`,
            icon: MoneyIcon,
          },
          isProviderOwner && {
            title: "Reports",
            path: `/${medspa}/reports`,
            icon: ReportsIcon,
          },
          {
            title: "Services & Products",
            path: `/${medspa}/settings/${DedicatedSettingsItem.SERVICES_AND_PRODUCTS}`,
            icon: MedsIcon,
          },
          isProviderOwner &&
            complianceHubV1Enabled && {
              title: "Compliance Hub",
              path: `/${medspa}/compliance-hub`,
              icon: ShieldCheckIcon,
              notifications: ComplianceHubIssueCounterNotificationDot,
            },
          SettingsItem,
          ...HelpDeskItems,
          {
            title: "Report a Bug",
            onClick: () => {
              window.birdeatsbug.trigger();
            },
            icon: WarningCircleIcon,
          },
        ],
      },
    ];

    const navDataMD: NavDataType = [
      {
        items: [
          ReviewerGfeItem,
          {
            title: "Charts",
            path: mdChartsV1Enabled
              ? `/${medspa}/review/charts`
              : `/${medspa}/review-visits/charts`,
            icon: PinpaperFilledIcon,
            getIsActive: (url) =>
              url.includes(`/review/charts`) ||
              /\/review-visits\/\d+\/chart/.test(url), // match /review-visits/:id/chart
          },
          {
            title: "Clinical Documents",
            path: `/${medspa}/settings/medical-director/${DedicatedSettingsItem.CLINICAL_DOCUMENTS}`,
            icon: DocumentIcon,
          },
          complianceHubV1Enabled &&
            complianceMdMeetingsV1Enabled && {
              title: "Provider Meetings",
              path: `/${medspa}/provider-meetings`,
              icon: CalendarUserIcon,
            },
          ClientsItem,
          adverseReactionsV1Enabled && {
            title: "Adverse reactions",
            path: `/${medspa}/adverse-reactions`,
            icon: UserWarningIcon,
            notifications: PendingAdverseReactionsNotificationDot,
          },
          {
            title: "Billing",
            path: `/${medspa}/settings/medical-director/${DedicatedSettingsItem.BILLING}`,
            icon: CoinIcon,
          },
          // Must be provider owner or PC owner to see balance page + feature flag enabled
          balanceEnabled &&
            showBalance && {
              title: "Balance",
              path: `/${medspa}/balance`,
              icon: CoinIcon,
            },
          SettingsItem,
          ...HelpDeskItems,

          {
            title: "Report a Bug",
            onClick: () => {
              window.birdeatsbug.trigger();
            },
            icon: WarningCircleIcon,
          },
        ],
      },
    ];

    const navDataGFEReviewer: NavDataType = [
      {
        items: [
          ReviewerGfeItem,
          {
            title: "Scripts",
            path: `/${medspa}/scripts`,
            icon: CapsuleIcon,
          },
          SettingsItem,
          ...HelpDeskItems,
          {
            title: "Report a Bug",
            onClick: () => {
              window.birdeatsbug.trigger();
            },
            icon: WarningCircleIcon,
          },
        ],
      },
    ];

    const navDataFDS: NavDataType = [
      {
        items: [
          {
            title: "Schedule",
            path: `/${medspa}/visits`,
            icon: CalendarIcon,
          },
          ClientsItem,
          {
            title: "Report a Bug",
            onClick: () => {
              window.birdeatsbug.trigger();
            },
            icon: WarningCircleIcon,
          },
        ],
      },
    ];

    const data = {
      [Roles.PROVIDER]: navDataProvider,
      [Roles.MEDICAL_DIRECTOR]: isGfeReviewer ? navDataGFEReviewer : navDataMD,
      [Roles.FRONT_DESK]: navDataFDS,
    };

    return filterForVisibleItems(data[role] || []);
  }, [
    medspa,
    role,
    newGfeFlowV1,
    isProviderOwner,
    isEligibleForSelfGfeReview,
    isGfeReviewer,
    balanceEnabled,
    showBalance,
    complianceHubV1Enabled,
    mdChartsV1Enabled,
    complianceMdMeetingsV1Enabled,
    adverseReactionsV1Enabled,
    syncGfeV1Enabled,
    commandAiEnabled,
    contentVaultV1Enabled,
  ]);

  return { navData };
}
