import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import useProtocolsAndStandingOrdersTableData from "@/hooks/complianceHub/useProtocolsAndStandingOrdersTableData";
import useServiceDocumentsTableData from "@/hooks/complianceHub/useServiceDocumentsTableData";

// Make sure issues from all compliance hub tables/views are summed up here
// When a new section's issue count is included here it should be put behind a corresponding feature flag
export default function useComplianceHubIssueCount() {
  const { complianceHubV2Enabled, complianceHubV3Enabled } = useFeatureFlags();

  let issuesSum = 0;
  const { issues: serviceDocumentsIssueCount } = useServiceDocumentsTableData();
  const { issues: protocolsAndStandingOrdersIssues } =
    useProtocolsAndStandingOrdersTableData();

  if (complianceHubV3Enabled) issuesSum += serviceDocumentsIssueCount;
  if (complianceHubV2Enabled) issuesSum += protocolsAndStandingOrdersIssues;

  return issuesSum;
}
