import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useUser } from "@/auth/useUser";
import { hasRole } from "@/auth/useUser";
import { useTimeSelectorContext } from "@/components/booking/timeSelector/timeSelectorContext";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useCreateMdMeetingDrawer } from "@/contexts/createMdMeetingDrawerContext";
import { useCreateMdMeetingMutation } from "@/graphql/mutations/createMdMeeting.graphql.types";
import { useRescheduleMdMeetingMutation } from "@/graphql/mutations/rescheduleMdMeeting.graphql.types";
import { useMedspaMedicalDirectors } from "@/hooks/common/useMedspaMedicalDirectors";
import { useMedspaProviders } from "@/hooks/common/useMedspaProviders";
import { MEDICAL_DIRECTOR, PROVIDER } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";
import useUserHasRoles from "../user/useUserHasRoles";

type Attendee = { id: string; label: string };

type CreateMDMeetingForm = {
  providers: Attendee[];
  mds: Attendee[];
  meetingDetails?: string;
  format: string;
};

const schema = yup.object().shape({
  providers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one provider must attend this meeting"),
  mds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one MD must attend this meeting"),
  meetingDetails: yup.string(),
});

export function useCreateMdMeetingForm() {
  const { userMedspa } = useUser();
  const logError = useErrorLogger();
  const { getConfirm } = useConfirm();
  const { medspaProviders } = useMedspaProviders();
  const { isOpen, closeDrawer, currentMeeting, drawerTitle } =
    useCreateMdMeetingDrawer();
  const { medspaMedicalDirectors } = useMedspaMedicalDirectors();
  const isMd = useUserHasRoles([MEDICAL_DIRECTOR]);

  const {
    isOpen: isTimeSelectorOpen,
    dateRange,
    setEnd,
    setStart,
  } = useTimeSelectorContext();

  const [createMdMeetingMutation, { loading: isCreating }] =
    useCreateMdMeetingMutation({
      refetchQueries: ["MdMeetingsByMedspa"],
    });

  const [rescheduleMdMeetingMutation, { loading: isRescheduling }] =
    useRescheduleMdMeetingMutation({
      refetchQueries: ["MdMeetingsByMedspa"],
    });

  const [hasSetEndTime, setHasSetEndTime] = useState(false);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateMDMeetingForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      meetingDetails: "",
      providers: [],
      mds: [],
    },
    mode: "onChange",
  });

  const mds = watch("mds");
  const providers = watch("providers");

  const resetFields = useCallback(() => {
    // Form fields
    reset({
      meetingDetails: "",
      providers: [],
      mds: [],
    });
    // Date and Time selector fields
    setStart(null);
    setEnd(null);
  }, [reset, setStart, setEnd]);

  const setFieldsWithCurrentMeeting = useCallback(
    (currentMeeting) => {
      const providers = currentMeeting.attendees
        .filter((attendee) => hasRole(attendee.userMedspa, [PROVIDER]))
        .map((provider) => ({
          id: provider.userMedspa.id,
          label: provider.userMedspa.user.fullName,
        }));

      const mds = currentMeeting.attendees
        .filter((attendee) => hasRole(attendee.userMedspa, [MEDICAL_DIRECTOR]))
        .map((md) => ({
          id: md.userMedspa.id,
          label: md.userMedspa.user.fullName,
        }));

      // Set form fields
      reset({
        meetingDetails: currentMeeting.details || "",
        providers,
        mds,
      });

      // Set date and time selector fields
      setStart(new Date(currentMeeting.startTime));
      setEnd(new Date(currentMeeting.endTime));
    },
    [reset, setEnd, setStart]
  );

  /**
   * Setting form fields with current meeting details or empty values
   * if no meeting is selected meaning we are creating a new meeting.
   * This will run every time the drawer is opened.
   */
  useEffect(() => {
    if (isOpen) {
      if (currentMeeting) {
        // Editing mode
        setFieldsWithCurrentMeeting(currentMeeting);
      } else {
        // Creating mode
        resetFields();
      }
    }
  }, [currentMeeting, isOpen, resetFields, setFieldsWithCurrentMeeting]);

  const providerOptions = useMemo(() => {
    return medspaProviders?.map((provider) => ({
      id: provider.id,
      label: provider.user.fullName,
    }));
  }, [medspaProviders]);

  const mdOptions = useMemo(() => {
    if (isMd && userMedspa) {
      const currentMd = medspaMedicalDirectors?.find(
        (md) => md.id === userMedspa.id
      );
      if (currentMd) {
        return [
          {
            id: currentMd.id,
            label: currentMd.user.fullName,
          },
        ];
      }
    }
    return medspaMedicalDirectors?.map((md) => ({
      id: md.id,
      label: md.user.fullName,
    }));
  }, [medspaMedicalDirectors, isMd, userMedspa]);

  const isCreateButtonEnabled = Boolean(
    isValid &&
      mds.length > 0 &&
      providers.length > 0 &&
      dateRange?.start &&
      dateRange?.end
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!dateRange?.start || !dateRange?.end || !userMedspa) return;

    const attendeesIds = [
      ...data.providers.map((provider) => parseInt(provider.id)),
      ...data.mds.map((md) => parseInt(md.id)),
    ];

    const toastId = toast.loading(
      currentMeeting ? "Updating MD meeting..." : "Creating MD meeting..."
    );

    try {
      const bookingFlow = isMd ? "md_menu" : "provider_compliance_hub";
      const format = "video_or_phone";

      if (currentMeeting) {
        // Editing mode
        const sendCommunication = await getConfirm({
          title: "Send notification to attendees",
          description:
            "Do you want to send an email to attendees with information about updated meeting details?",
          discardButtonText: "No, don’t send",
          confirmButtonText: "Yes, send",
        });

        await rescheduleMdMeetingMutation({
          variables: {
            mdMeetingId: currentMeeting.id,
            bookingFlow,
            details: data.meetingDetails || "",
            endTime: dateRange.end.toISOString(),
            attendeesIds: attendeesIds.map(String),
            startTime: dateRange.start.toISOString(),
            sendCommunication,
          },
        });
        toast.success("MD meeting updated successfully", { id: toastId });
      } else {
        // Creating mode
        await createMdMeetingMutation({
          variables: {
            bookedById: userMedspa.id,
            bookingFlow,
            format,
            details: data.meetingDetails || "",
            endTime: dateRange.end.toISOString(),
            attendeesIds: attendeesIds.map(String),
            startTime: dateRange.start.toISOString(),
          },
        });
        toast.success("MD meeting created successfully", { id: toastId });
      }

      closeDrawer();
    } catch (error) {
      logError(error.message);
      toast.error(
        hasDjangoMutationError(error)
          ? error.message
          : `Unable to ${currentMeeting ? "update" : "create"} MD meeting. Please contact support team.`,
        { id: toastId }
      );
      closeDrawer();
    }
  });

  return {
    // Drawer state
    drawerTitle,
    isOpen,
    isEditing: Boolean(currentMeeting),
    closeDrawer,

    // Form control and validation
    control,
    errors,
    isCreateButtonEnabled,
    onSubmit,

    // Options for autocomplete fields
    providerOptions,
    mdOptions,

    // Time selector state
    isTimeSelectorOpen,
    hasSetEndTime,
    setHasSetEndTime,

    // Loading state
    isLoading: isCreating || isRescheduling,
  };
}
