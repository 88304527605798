import {
  CardiologyIcon,
  CheckCircleIcon,
  CheckSmallIcon,
  ClockIcon,
  CrossSmallIcon,
  MinusSmallIcon,
  PinpaperCrossIcon,
} from "@/components/common/icons";
import IconCircleContainer from "@/components/common/icons/containers/iconCircleContainer";
import IconContainer from "@/components/common/icons/containers/iconContainer";
import {
  ERROR_PRIMARY,
  GREEN,
  GREY,
  MINT,
  SALMON,
  TEXT_PRIMARY_DARK,
  WHITE,
  YELLOW,
} from "@/config/mui/colorPalette";

export const ReviewRequestedIcon = ({ size = "24px" }) => (
  <IconContainer
    size={size}
    icon={<ClockIcon size={size} color={YELLOW[100]} />}
  />
);

export const ContraindicatedIcon = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={SALMON[40]}
    icon={<CrossSmallIcon color={SALMON[100]} />}
  />
);

export const NotIndicatedIcon = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={GREY[30]}
    icon={<CrossSmallIcon color={TEXT_PRIMARY_DARK} />}
  />
);

export const IndicatedIcon = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={MINT[40]}
    icon={<CheckSmallIcon color={MINT[100]} />}
  />
);

export const FormExpiredIcon = ({ size = "24px" }) => (
  <IconContainer
    size={size}
    icon={<PinpaperCrossIcon size={size} color={ERROR_PRIMARY} />}
  />
);

export const FormUnsentIcon = ({ size = "24px" }) => (
  <IconContainer
    size={size}
    icon={<MinusSmallIcon size={size} color={GREY[60]} />}
  />
);

export const IncompleteFormIcon = ({ size = "24px" }) => (
  <IconContainer size={size} icon={<PinpaperCrossIcon color={SALMON[100]} />} />
);

export const CompletedGfeIcon = ({ size = "24px" }) => (
  <IconContainer
    size={size}
    icon={<CheckCircleIcon size={size} color={GREEN[100]} />}
  />
);

export const ServiceIncompleteFormIconBordered = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={WHITE}
    icon={<CardiologyIcon size="18px" color={SALMON[100]} />}
    sx={{ border: `1px solid ${SALMON[80]}` }}
  />
);

export const ServiceIncompleteFormIcon = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={WHITE}
    icon={<CardiologyIcon size="18px" color={SALMON[100]} />}
  />
);

export const ServiceReviewRequestedIcon = ({ size = "24px" }) => (
  <IconCircleContainer
    size={size}
    color={WHITE}
    icon={<CardiologyIcon size="18px" color={YELLOW[100]} />}
    sx={{ border: `1px solid ${YELLOW[80]}` }}
  />
);

export const ServiceIndicatedIcon = ({ size = "24px" }) => {
  return (
    <IconCircleContainer
      size={size}
      color={MINT[40]}
      icon={<CardiologyIcon size="18px" color={MINT[100]} />}
    />
  );
};

export const ServiceNotIndicatedIcon = ({ size = "24px" }) => {
  return (
    <IconCircleContainer
      size={size}
      color={GREY[30]}
      icon={<CardiologyIcon size="18px" color={GREY[60]} />}
    />
  );
};

export const ServiceContraindicatedIcon = ({ size = "24px" }) => {
  return (
    <IconCircleContainer
      size={size}
      color={SALMON[40]}
      icon={<CardiologyIcon size="18px" color={SALMON[100]} />}
    />
  );
};

export const ServiceNotNeededIcon = ({ size = "24px" }) => {
  return (
    <IconCircleContainer
      size={size}
      color={GREY[30]}
      icon={<MinusSmallIcon size={size} color={TEXT_PRIMARY_DARK} />}
    />
  );
};
