import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ProtocolsAndStandingOrdersTableQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
}>;

export type ProtocolsAndStandingOrdersTableQuery = {
  __typename?: "query_root";
  medspaServiceOffering: Array<{
    __typename?: "MedspaServiceOffering";
    id: string;
    serviceType: { __typename?: "ServiceType"; id: string; name: string };
  }>;
  providers: Array<{
    __typename?: "userMedspa";
    id: string;
    providerNotRequireDocuments: Array<{
      __typename?: "ProviderNotRequireDocuments";
      id: string;
      documentType: string;
      medspaServiceOfferingId: string;
      providerId: string;
      created: string;
    }>;
    providerServiceProtocols: Array<{
      __typename?: "ServiceProtocol";
      id: string;
      status: string;
      serviceTypeId?: string | null;
      created: string;
      pdfFileSource: string;
    }>;
    providerStandingOrders: Array<{
      __typename?: "tmpStandingOrder";
      id: string;
      status: string;
      serviceTypeId?: string | null;
      created: string;
      pdfFileSource: string;
    }>;
    user: {
      __typename?: "user";
      id: string;
      firstName: string;
      lastName: string;
    };
  }>;
};

export const ProtocolsAndStandingOrdersTableDocument = gql`
  query ProtocolsAndStandingOrdersTable($medspaId: bigint!) {
    medspaServiceOffering(
      where: {
        isEnabled: { _eq: true }
        isMedicalService: { _eq: true }
        medspa: { id: { _eq: $medspaId } }
      }
      orderBy: { serviceType: { serviceTypeCategory: { order: ASC } } }
    ) {
      id
      serviceType {
        id
        name
      }
    }
    providers: userMedspa(
      where: {
        medspaId: { _eq: $medspaId }
        role: { _eq: "provider" }
        active: { _eq: true }
      }
      orderBy: { user: { firstName: ASC } }
    ) {
      id
      providerNotRequireDocuments {
        id
        documentType
        medspaServiceOfferingId
        providerId
        created
      }
      providerServiceProtocols(where: { archivedAt: { _isNull: true } }) {
        id
        status
        serviceTypeId
        created
        pdfFileSource
      }
      providerStandingOrders(where: { archivedAt: { _isNull: true } }) {
        id
        status
        serviceTypeId
        created
        pdfFileSource
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useProtocolsAndStandingOrdersTableQuery__
 *
 * To run a query within a React component, call `useProtocolsAndStandingOrdersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProtocolsAndStandingOrdersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProtocolsAndStandingOrdersTableQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useProtocolsAndStandingOrdersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >(ProtocolsAndStandingOrdersTableDocument, options);
}
export function useProtocolsAndStandingOrdersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >(ProtocolsAndStandingOrdersTableDocument, options);
}
export function useProtocolsAndStandingOrdersTableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ProtocolsAndStandingOrdersTableQuery,
    ProtocolsAndStandingOrdersTableQueryVariables
  >(ProtocolsAndStandingOrdersTableDocument, options);
}
export type ProtocolsAndStandingOrdersTableQueryHookResult = ReturnType<
  typeof useProtocolsAndStandingOrdersTableQuery
>;
export type ProtocolsAndStandingOrdersTableLazyQueryHookResult = ReturnType<
  typeof useProtocolsAndStandingOrdersTableLazyQuery
>;
export type ProtocolsAndStandingOrdersTableSuspenseQueryHookResult = ReturnType<
  typeof useProtocolsAndStandingOrdersTableSuspenseQuery
>;
export type ProtocolsAndStandingOrdersTableQueryResult = Apollo.QueryResult<
  ProtocolsAndStandingOrdersTableQuery,
  ProtocolsAndStandingOrdersTableQueryVariables
>;
