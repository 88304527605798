import { datadogRum } from "@datadog/browser-rum";
import { PUBLIC_ENVIRONMENT } from "@/config";

// We may move these to environment variables in the future
// Currently, these are hardcoded because they do not change from env to env
const DATADOG_APP_ID = "4db23ee5-b274-41b2-aed3-01e8c3684db2";
const DATADOG_CLIENT_TOKEN = "pub6c1e78af147fc4d25bdb2fe5c6d6942c";

datadogRum.init({
  applicationId: DATADOG_APP_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "moxie_suite_ui",
  env: PUBLIC_ENVIRONMENT === "production" ? "prod" : PUBLIC_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  version: process.env.NEXT_PUBLIC_MOXIEINFRA_COMMIT_SHA,
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    {
      match: process.env.NEXT_PUBLIC_GRAPHQL_URI,
      propagatorTypes: ["tracecontext"],
    },
  ],
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
